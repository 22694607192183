import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { UserDetailsContext } from "src/context/User"
import {
  SearchContext,
  FilterContext,
  AddContactContext
} from "src/context/Search"
import { RouteType } from "src/constants/routeTypes"
import { ROOTSTATE } from "src/utils/modules"
import isProduction from "src/utils/environmentUtils"

import "./Header.scss"
import { useUser } from "../../api/user"
import LanguageMenu from "./LanguageMenu"
import useFetchTranslations from "./hooks/useFetchTranslations"

const logo = "/images/bmi-logo.png"
const filterImg = "/images/navigation/Filter.svg"
const bellImg = "/images/navigation/bell.svg"
const logoutImg = "/images/Exit.svg"
const arrowLeftImg = "/images/ArrowLeft.svg"
const serachImg = "/images/navigation/Search.svg"
const addNewUserImg = "/images/navigation/AddNewUser.svg"

const Header: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { userDetails, setUserDetails } = useContext(UserDetailsContext)
  const { isSearch, setIsSearch } = useContext(SearchContext)
  const { setIsFilter } = useContext(FilterContext)
  const { setAddContact } = useContext(AddContactContext)
  const userEmail = localStorage.getItem("email")
  const [menuSelected, setMenuSelected] = useState("dashboard")
  const [heading, setHeading] = useState<string>("")
  const [userBankDetails, setUserBankDetailsData] = useState<any>(null)
  const [selectedMappedLanguage, setSelectedMappedLanguage] = useState<any>()

  // get mapped languages
  const { languages } = useFetchTranslations(
    {
      selectedLanguageFile: selectedMappedLanguage?.resourceFileURL || null,
      defaultLanguageCode: "en",
      fallbackLanguageCode: "en",
      languageKey: "translation"
    },
    selectedMappedLanguage
  )

  // Fetch user details
  const query = `email=${userEmail}`
  const {
    data: userDetailsData,
    refetch: refetchUserDetailsData,
    isSuccess: userIsSuccess
  } = useUser(query)

  // refetch User Details
  useEffect(() => {
    if (userEmail) {
      refetchUserDetailsData()
    }
  }, [userEmail])

  // Set user bank details
  useEffect(() => {
    if (userDetailsData) {
      setUserBankDetailsData(userDetailsData)
    }
  }, [userIsSuccess])

  // Set user details data
  useEffect(() => {
    if (userBankDetails) {
      setUserDetails({
        cognitoUsername: userBankDetails?.cognitoUsername,
        email: userBankDetails?.email,
        phoneNumber: userBankDetails?.phoneNumber,
        firstName: userBankDetails?.firstName,
        lastName: userBankDetails?.lastName,
        bankAccountNumber:
          userBankDetails?.userBankAccount?.[0]?.bankAccountNumber,
        ifscCode: userBankDetails?.userBankAccount?.[0]?.ifscCode,
        notificationStatus: userBankDetails?.notificationStatus,
        kycVerified: userBankDetails?.kycVerified,
        userBankAccount: userBankDetails?.userBankAccount
      })
    }
  }, [userBankDetails, setUserDetails])

  // Set menu selection and heading based on location
  useEffect(() => {
    if (location) {
      if (location.pathname === "/dashboard") {
        setMenuSelected("dashboard")
        setHeading("Home")
      } else if (location.pathname === "/internaltransfer") {
        setMenuSelected("internaltransfer")
        setHeading(t("transfer"))
      } else if (location.pathname.includes("topup")) {
        setMenuSelected("topup")
        setHeading(t("topup"))
      } else if (location.pathname.includes("transaction-confirmation")) {
        setMenuSelected("transactionConfirmation")
        setHeading(t("transactionConfirmation"))
      } else if (location.pathname.includes("invoice")) {
        setMenuSelected("invoice")
      } else if (location.pathname.includes("settings")) {
        setMenuSelected("settings")
        setHeading(t("settings"))
      } else if (location.pathname.includes("refund-page")) {
        setMenuSelected("refund")
        setHeading(t("redeemUnspent"))
      } else if (location.pathname.includes("payment-details")) {
        setMenuSelected("paymentDetails")
        setHeading("")
      } else if (location.pathname.includes("PaymentSuccess")) {
        setMenuSelected("PaymentSuccess")
        setHeading("Payment Receipt")
      } else if (location.pathname.includes("transactions")) {
        setMenuSelected("transactions")
        setHeading(t("transactions"))
      } else if (location.pathname.includes("verify-phone-otp")) {
        setMenuSelected("webpage_poc")
        setHeading("Verify Phone Number")
      } else if (location.pathname.includes("passcode")) {
        setMenuSelected("passcode")
        setHeading("Set Passcode")
      } else if (location.pathname.includes("profile")) {
        setMenuSelected("profile")
        setHeading(t("userDetails"))
      } else if (location.pathname.includes("manage-contacts")) {
        if (location.state?.from?.includes("/internaltransfer")) {
          setHeading(t("recipientAddress"))
        } else {
          setHeading(t("manageContacts"))
        }
        setMenuSelected("manageContact")
      } else if (location.pathname.includes("show-my-qr")) {
        setMenuSelected("showMyQR")
        setHeading(t("saveQRCode"))
      } else if (location.pathname.includes("scan-qr-code")) {
        setMenuSelected("scan-qr-code")
        setHeading(t("scanAndPay"))
      } else if (location.pathname.includes("scan-qr-code-paying")) {
        setMenuSelected("scan-qr-code")
        setHeading(t("paymentConfirmation"))
      } else if (location.pathname.includes("send-external-component")) {
        setMenuSelected("send-external-component")
        setHeading(t("paymentConfirmation"))
      }
    }
  }, [location])

  // Selector
  const isBackIconDisabled = useSelector(
    (store: ROOTSTATE) => store.appHeader.isDisabled
  )

  const onChangeLang = async (lang_code: string, resourceFileURL: string) => {
    const obj = {
      selectedLanguage: lang_code
    }

    localStorage.setItem(`${userDetails?.email}`, JSON.stringify(obj))

    setSelectedMappedLanguage({ lang_code, resourceFileURL })
  }

  return userDetails ? (
    <>
      <nav
        className="navbar navbar-expand-md navbar-light sticky-top mb-3"
        role="navigation"
      ></nav>
      <nav
        className="navbar fixed-top navbar-light shadow"
        role="navigation"
        style={{ zIndex: 999 }}
      >
        <div className="d-flex flex-row w-100">
          {menuSelected === "dashboard" ? (
            <>
              <Link to={RouteType.DASHBOARD}>
                <img
                  className={menuSelected === "dashboard" ? "main-logo" : ""}
                  src={logo}
                  alt="Logo"
                />
              </Link>
              <div
                className={"languages"}
                style={{ position: "absolute", right: 5, top: "20px" }}
              >
                <LanguageMenu
                  languages={languages}
                  onChangeLang={onChangeLang}
                />
                <img
                  style={{ width: "25px", marginRight: "5px" }}
                  src={bellImg}
                  alt="Bell Icon"
                />
                <Link to={RouteType.WADZPAY_AUTO_LOGOUT}>
                  <img
                    style={{ width: "25px" }}
                    className={"ml-5"}
                    src={logoutImg}
                    alt="Logout Icon"
                  />
                </Link>
              </div>
            </>
          ) : (
            <div style={{ width: "100%", marginLeft: "10px" }}>
              {!(
                menuSelected === "settings" ||
                menuSelected === "scan-qr-code" ||
                menuSelected === "transactions" ||
                menuSelected === "internaltransfer"
              ) && (
                <img
                  className="float-start active-tab me-2"
                  src={arrowLeftImg}
                  onClick={
                    !isBackIconDisabled
                      ? () => {
                          menuSelected === "passcode"
                            ? navigate(RouteType.SETTINGS)
                            : menuSelected === "PaymentSuccess"
                            ? navigate(RouteType.DASHBOARD)
                            : menuSelected === "paymentDetails"
                            ? navigate(RouteType.TRANSACTIONS)
                            : navigate(-1)
                        }
                      : undefined
                  }
                  style={{
                    width: "25px",
                    marginTop: "15px",
                    cursor: isBackIconDisabled ? "pointer" : "default"
                  }}
                  alt="Back Icon"
                />
              )}

              {menuSelected === "manageContact" ||
              menuSelected === "transactions" ? (
                <>
                  <div
                    className="search-icon"
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "10px"
                    }}
                  >
                    <img
                      className={
                        menuSelected === "manageContact" ? "active-tab" : "null"
                      }
                      onClick={() => setIsSearch(true)}
                      style={{ marginRight: "10px", width: "30px" }}
                      src={serachImg}
                      alt="Search Icon"
                    />
                    {menuSelected === "transactions" ? (
                      <img
                        onClick={() => setIsFilter(true)}
                        src={filterImg}
                        style={{ width: "30px" }}
                        alt="Filter Icon"
                      />
                    ) : null}
                    {menuSelected === "manageContact" ? (
                      <img
                        className="active-tab"
                        onClick={() => setAddContact(true)}
                        src={addNewUserImg}
                        style={{ width: "30px" }}
                        alt="Add User Icon"
                      />
                    ) : null}
                  </div>
                </>
              ) : null}

              <h5
                style={{
                  fontSize: "20px",
                  color: "rgb(87 86 86)",
                  fontStyle: "rubik",
                  marginTop: "13px"
                }}
                className="heading"
              >
                &nbsp;&nbsp;{heading}
              </h5>
            </div>
          )}
        </div>
      </nav>
    </>
  ) : null
}

export default Header
