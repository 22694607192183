import { Modal } from "antd"
import { useTranslation } from "react-i18next"

export const shownames = (item: any) => {
  if (item.direction === "OUTGOING") {
    if (item.transactionType === "WITHDRAW") {
      return item.asset == "SART" ? "Redeem Unspent" : "Withdraw"
    } else if (item.transactionType === "DEPOSIT") {
      return item.asset == "SART" ? "Topup" : "Deposit"
    } else if (item.transactionType === "BUY") {
      return "Buy"
    } else if (item.transactionType === "SELL") {
      return "Sell"
    } else if (item.transactionType === "SERVICE_FEE") {
      return "Service Fee"
    } else if (item.transactionType === "WALLET_FEE") {
      return "Wallet Low Balance Fee"
    } else {
      return item.receiverName
    }
  } else if (item.direction === "INCOMING") {
    if (item.transactionType === "WITHDRAW") {
      return "Withdraw"
    } else if (item.transactionType === "DEPOSIT") {
      return item.asset == "SART" ? "Topup" : "Deposit"
    } else if (item.transactionType === "BUY") {
      return "Buy"
    } else if (item.transactionType === "SELL") {
      return "Sell"
    } else {
      return item.senderName
    }
  }
}

export const getActionName = (transaction: any, transactionType: string) => {
  switch (transactionType) {
    case "ON_RAMP":
      return "Net On Ramp Amount"
    case "OFF_RAMP":
      return "Net Off Ramp Amount"
    case "MERCHANT":
      return "Amount Paid"
    case "PEER_TO_PEER":
      return transaction?.asset == "SART"
        ? "Net Transfer Amount"
        : "Wadzpay Wallet"
    case "OTHER":
    case "EXTERNAL_SEND":
    case "EXTERNAL_RECEIVE":
    case "POS":
      return transaction?.asset == "SART" ? "Amount Paid" : "External Wallet"
    case "DEPOSIT":
      return transaction?.asset == "SART" ? "Amount Debited1" : "Deposit"
    case "WITHDRAW":
      return transaction?.asset == "SART" ? "Net Redeem Token" : "Withdraw"
    case "REFUND":
      return transaction?.asset == "SART"
        ? "Net Refund Amount"
        : "External Wallet"
    case "SERVICE_FEE":
      return "Service Fee"
    case "WALLET_FEE":
      return "Wallet Low Balance Fee"
    default:
      return transactionType
  }
}

export const getTransactionType = (
  transaction: any,
  transactionType: string
) => {
  switch (transactionType) {
    case "ON_RAMP":
      return "On Ramp"
    case "OFF_RAMP":
      return "Off Ramp"
    case "MERCHANT":
      return "POS"
    case "PEER_TO_PEER":
      return transaction?.asset == "SART" ? "Transfer" : "Wadzpay Wallet"
    case "OTHER":
    case "EXTERNAL_SEND":
    case "EXTERNAL_RECEIVE":
    case "POS":
      return transaction?.asset == "SART" ? "Payment" : "External Wallet"
    case "DEPOSIT":
      return transaction?.asset == "SART" ? "Topup" : "Deposit"
    case "WITHDRAW":
      return transaction?.asset == "SART" ? "Redeem Unspent" : "Withdraw"
    case "REFUND":
      return transaction?.asset == "SART"
        ? "Merchant Refund"
        : "External Wallet"
    case "SERVICE_FEE":
      return "Service Fee"
    case "WALLET_FEE":
      return "Wallet Low Balance Fee"
    default:
      return transactionType
  }
}

export const getTotalName = (transaction: any, transactionType: string) => {
  switch (transactionType) {
    case "ON_RAMP":
      return "Amount Received"
    case "OFF_RAMP":
      return "Amount Transferred"
    case "MERCHANT":
      return "Tokens Paid"
    case "PEER_TO_PEER":
      return transaction?.asset == "SART"
        ? "Tokens Transferred"
        : "Wadzpay Wallet"
    case "OTHER":
    case "EXTERNAL_SEND":
    case "EXTERNAL_RECEIVE":
    case "POS":
      return transaction?.asset == "SART" ? "Tokens Paid" : "External Wallet"
    case "DEPOSIT":
      return transaction?.asset == "SART" ? "Received Tokens" : "Deposit"
    case "WITHDRAW":
      return transaction?.asset == "SART" ? "Redeemed Amount" : "Withdraw"
    case "REFUND":
      return transaction?.asset == "SART" ? "Refund Tokens" : "External Wallet"
    case "SERVICE_FEE":
      return "Service Fee"
    case "WALLET_FEE":
      return "Wallet Low Balance Fee"
    default:
      return transactionType
  }
}

export const CapitalizeFirstLetter = (str: string) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export const getFromToValues = (details: any) => {
  if (details) {
    if (details.length == 2) {
      return details[1].length > 6
        ? "x".repeat(details[1].length - 4) + " " + details[1].slice(-4)
        : "Wallet"
    }
    return "Wallet"
  }
  return ""
}

export const showFromFeildValue = (transaction: any, userEmailId: any) => {
  if (transaction.transactionType === "DEPOSIT") {
    const details = transaction.description.split("From")
    return getFromToValues(details)
  } else if (transaction.transactionType === "WITHDRAW") {
    return transaction?.senderFirstName
      ? `${transaction?.senderFirstName} ${
          transaction?.senderLastName !== null
            ? transaction?.senderLastName
            : ""
        }`
      : userEmailId
  } else {
    return transaction?.senderFirstName
      ? `${transaction?.senderFirstName} ${
          transaction?.senderLastName !== null
            ? transaction?.senderLastName
            : ""
        }`
      : transaction.senderName
  }
}

export const showToFeildValue = (transaction: any) => {
  if (transaction.transactionType === "WITHDRAW") {
    const details = transaction.description.split("To")
    return getFromToValues(details)
  } else if (
    transaction.transactionType === "SERVICE_FEE" ||
    transaction.transactionType === "WALLET_FEE"
  ) {
    return "WadzPay"
  } else {
    return transaction?.receiverFirstName && transaction?.receiverLastName
      ? `${transaction?.receiverFirstName} ${
          transaction?.receiverLastName !== null
            ? transaction?.receiverLastName
            : ""
        }`
      : transaction.receiverName
  }
}

let showModalCount = 0
export const errorModal = (message: string, typeSuccess?: boolean) => {
  if (showModalCount === 0) {
    showModalCount = showModalCount + 1
    // console.log("showModalCount ", showModalCount)
    if (typeSuccess) {
      Modal.success({
        content: message,
        onOk() {
          showModalCount = 0
        }
      })
    } else {
      Modal.error({
        title: "An error occured.",
        content: message,
        onOk() {
          showModalCount = 0
        }
      })
    }
  }
}

export const groupBy = <T, K extends keyof any>(
  array: T[],
  getKey: (item: T) => K
) =>
  array.reduce((previous, currentItem) => {
    const group = getKey(currentItem)
    if (!previous[group]) previous[group] = []
    previous[group].push(currentItem)
    return previous
  }, {} as Record<K, T[]>)

export const setAmountTwoDecimal = (amount: number) => {
  return isDecimalNumber(amount)
    ? parseFloat(amount.toFixed(2)).toLocaleString()
    : amount.toLocaleString()
}

export const getAmountTwoDecimal = (amount: number) => {
  return checkDecimalPlaces(amount)
}

function isDecimalNumber(value: number) {
  // Check if the value is a finite number and has a fractional part //
  return Number.isFinite(value) && value % 1 !== 0
}

function checkDecimalPlaces(amount: number) {
  // Convert the number to a string
  const numString = amount.toFixed(2)

  // Check if the number has decimal places
  if (numString.includes(".")) {
    const decimalPartLength = numString.split(".")[1].length

    if (decimalPartLength >= 2) {
      return parseFloat(amount.toFixed(2)).toLocaleString()
    } else {
      return parseFloat(amount.toFixed(2)).toLocaleString() + "0"
    }
  } else {
    return amount.toLocaleString() + ".00"
  }
}

export const isConsideredPhoneNumber: (value: string) => boolean = (value) =>
  value.startsWith("+")

export const getParamsFromObject: (obj: {}) => URLSearchParams = (obj) =>
  new URLSearchParams(
    Object.entries(obj).reduce(
      (newParams, [key, val]) =>
        val
          ? {
              ...newParams,
              [key]: typeof val === "string" ? encodeURIComponent(val) : val
            }
          : newParams,
      {}
    )
  )

export const ConTwoDecDigit = (digit: any) => {
  return digit.includes(".")
    ? digit.split(".").length >= 2
      ? digit.split(".")[0] + "." + digit.split(".")[1].substring(-1, 2)
      : digit
    : digit
}

export const isTransactionAllowed = (
  transactionConfigData: any,
  amount: number,
  t: any
) => {
  const isAmountExistInMaxMin = validatePerTransactionData(
    transactionConfigData.per_TRANSACTION,
    amount
  )

  const yearlyValidateTransaction = validateTransaction(
    transactionConfigData.yearly,
    amount
  )
  const halfYealyValidateTransaction = validateTransaction(
    transactionConfigData.half_YEARLY,
    amount
  )
  const quaterlyValidateTransaction = validateTransaction(
    transactionConfigData.quarterly,
    amount
  )
  const monthlyValidateTransaction = validateTransaction(
    transactionConfigData.monthly,
    amount
  )
  const weeklyValidateTransaction = validateTransaction(
    transactionConfigData.weekly,
    amount
  )
  const dailyValidateTransaction = validateTransaction(
    transactionConfigData.daily,
    amount
  )

  const isAllPermitted =
    isAmountExistInMaxMin &&
    yearlyValidateTransaction.isPermitted &&
    halfYealyValidateTransaction.isPermitted &&
    quaterlyValidateTransaction.isPermitted &&
    monthlyValidateTransaction.isPermitted &&
    weeklyValidateTransaction.isPermitted &&
    dailyValidateTransaction.isPermitted

  if (isAllPermitted) {
    return "allowed"
  } else {
    if (!isAmountExistInMaxMin) {
      return getErrorMessage(transactionConfigData?.per_TRANSACTION, amount, t)
    } else if (yearlyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Yearly limit exhausted"}`)
    } else if (yearlyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Yearly maximum limit exceeded"}`)
    } else if (halfYealyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Half yearly limit exhausted"}`)
    } else if (halfYealyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Half yearly maximum limit exceeded"}`)
    } else if (quaterlyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Quaterly limit exhausted"}`)
    } else if (quaterlyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Quaterly maximum limit exceeded"}`)
    } else if (monthlyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Monthly limit exhausted"}`)
    } else if (monthlyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Monthly maximum limit exceeded"}`)
    } else if (weeklyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Weekly limit exhausted"}`)
    } else if (weeklyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Weekly Maximum limit exceeded"}`)
    } else if (dailyValidateTransaction.msgType == "frequency error") {
      return t(`transactionConfig.${"Daily limit exhausted"}`)
    } else if (dailyValidateTransaction.msgType == "balance error") {
      return t(`transactionConfig.${"Daily Maximum limit exceeded"}`)
    } else {
      return t(`transactionConfig.${"Maximum limit exceeded"}`)
    }
  }
}

export const validateInitialLoading = (
  transactionConfigData: any,
  amount: number,
  t: any
) => {
  const isAmountExistInMaxMin = validatePerTransactionData(
    transactionConfigData.one_TIME,
    amount
  )
  if (!isAmountExistInMaxMin) {
    return (
      t("amountBetween") +
      ` ${transactionConfigData?.one_TIME?.minimumBalance} - ${transactionConfigData?.one_TIME?.maximumBalance}`
    )
  } else {
    return "allowed"
  }
}

const validatePerTransactionData = (obj: any, amount: number) => {
  let isAmountExistInMaxMin = true

  const maxBalance = obj?.maximumBalance
  const minBalance = obj?.minimumBalance
  if (amount === 0 && minBalance === 0 && maxBalance > 0) {
    return false
  } else if (maxBalance > 0 && minBalance >= 0) {
    // do comparison between maximumBalance and minimumBalance with amount

    isAmountExistInMaxMin =
      (amount < maxBalance || amount == maxBalance) &&
      (amount > minBalance || amount == minBalance)
    return isAmountExistInMaxMin
  } else if (maxBalance > 0 && minBalance == null) {
    // do comparison between maximumBalance with amount I.e amount <= maximumBalance

    isAmountExistInMaxMin = amount < maxBalance || amount == maxBalance
    return isAmountExistInMaxMin
  } else if (minBalance > 0 && maxBalance == null) {
    // do comparison between minimumBalance with amount I.e amount >= minimumBalance
    isAmountExistInMaxMin = amount > minBalance || amount == minBalance
    return isAmountExistInMaxMin
  }

  return isAmountExistInMaxMin
}

const validateTransaction = (obj: any, amount: number) => {
  if (
    (obj?.availableCount !== null && obj?.availableCount < 0) ||
    (obj?.availableCount !== null && obj?.availableCount == 0) ||
    (obj?.remainingMaximumBalance !== null &&
      amount > obj?.remainingMaximumBalance)
  ) {
    return { isPermitted: false, msgType: "frequency error" }
  } else if (obj?.maximumBalance == !null && amount > obj?.maximumBalance) {
    return { isPermitted: false, msgType: "balance error" }
  } else {
    return { isPermitted: true, msgType: "success" }
  }
}

export const getErrorMessage = (obj: any, amount: number, t: any) => {
  const minBalance = obj?.minimumBalance
  const maxBalance = obj?.maximumBalance
  if (amount === 0 && minBalance > 0) {
    return t("validAmountError")
  } else if (maxBalance > 0 && minBalance === 0) {
    return t("amountLessThanOrEqualTo") + ` ${maxBalance}`
  } else if (maxBalance > 0 && minBalance > 0) {
    return (
      t("amountBetween") + ` ${obj?.minimumBalance} - ${obj?.maximumBalance}`
    )
  } else if (maxBalance > 0 && minBalance == null) {
    return t("amountLessThanOrEqualTo") + ` ${obj?.maximumBalance}`
  } else if (minBalance > 0 && maxBalance == null) {
    return t("amountGreaterThanOrEqualTo") + ` ${obj?.minimumBalance}`
  }
}

export const findPercentage = (per: number, num: number) => {
  //(num/100)*per;
  return (num / 100) * per
}

export const formatPhoneNumber: (phoneNumber: string) => string = (
  phoneNumber
) => {
  return phoneNumber.replace(/[^+\d]/g, "")
}

export const sartTxt = "SAR* "

export const getDirection = (item: any, property: string) => {
  if (property === "name") {
    return item.direction === "INCOMING" ? "CreditArrow" : "DebitArrow"
  }
  return item.direction === "INCOMING"
    ? "success"
    : item.direction === "OUTGOING"
    ? "error"
    : "orange"
}

export const getOtherPartyName = (transaction: any, userEmailId: string) => {
  if (transaction.transactionType === "WITHDRAW") {
    const details = transaction.description.split("To")
    return getFromToValues(details)
  } else if (transaction.transactionType === "DEPOSIT") {
    const details = transaction.description.split("From")
    return getFromToValues(details)
  } else if (transaction.transactionType === "REFUND") {
    return transaction?.senderName
  } else if (transaction?.transactionType === "PEER_TO_PEER") {
    return transaction?.direction === "OUTGOING"
      ? transaction?.receiverFirstName && transaction?.receiverLastName
        ? `${transaction?.receiverFirstName} ${transaction?.receiverLastName}`
        : transaction.receiverName
      : transaction?.senderFirstName && transaction?.senderLastName
      ? `${transaction?.senderFirstName} ${transaction?.senderLastName}`
      : transaction.senderName
  } else if (
    transaction.transactionType === "SERVICE_FEE" ||
    transaction.transactionType === "WALLET_FEE"
  ) {
    return "WadzPay"
  } else {
    return transaction?.receiverFirstName && transaction?.receiverLastName
      ? `${transaction?.receiverFirstName} ${transaction?.receiverLastName}`
      : transaction.receiverName
  }
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export const getMaskedAccountNumber = (user: any) => {
  return user && user?.userBankAccount
    ? user?.userBankAccount[0]?.bankAccountNumber?.slice(-4)
    : "Please add bank account"
}

export const getAccountHolderName = (user: any) => {
  return user && user?.userBankAccount
    ? user?.userBankAccount[0]?.accountHolderName
    : "Please add account holder name"
}

export const getFiatCurrency = (user: any) => {
  return (
    (user && user?.userBankAccount && user?.userBankAccount[0]?.fiatCurrency) ||
    user.fiatCurrency
  )
}

export const getTranslatedString = (t: any, str: string, word: string) => {
  const parts = str.split(" ")
  parts[0] = t(word) // Replace "Daily" with the translated term
  return parts.join(" ")
}
