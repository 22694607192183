import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Space, Input, Button, Spin, notification } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { useNavigate, Link, useLocation } from "react-router-dom"
import "./ScanQRCode.scss"
import { RouteType } from "src/constants/routeTypes"
import { UserBalanceContext } from "src/context/User"
import { UserDetailsContext } from "src/context/User"
import { TransactionTypeControl } from "src/api/constants"

import {
  useSendDigitalCurrencyToExternalWallet,
  useTransactionValidationConfig
} from "../../api/user"
import {
  isTransactionAllowed,
  sartTxt,
  getAmountTwoDecimal
} from "../../utils/Utils"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

// const SendExternalComponent = (props: QRDataProps): JSX.Element => {
const SendExternalComponent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  let qrAmount = location?.state?.qrAmount
  const qrAddress = location?.state?.qrAddress
  const qrMerchantName = location?.state?.qrMerchantName
  const transactionTypeControlQuery =
    location?.state?.transactionTypeControlQuery
  const qrAsset = location?.state?.qrAsset
  const transactionId = location?.state?.transactionId
  const merchantId = location?.state?.merchantId
  const posId = location?.state?.posId

  const [paymentAmount, setPaymentAmount] = useState(
    qrAmount == "0.00" || qrAmount == "0" ? "" : qrAmount
  )
  const [insufficientBalance, setinsufficientBalance] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [showError, setShowError] = useState<string | undefined>("")

  const { userBalances } = useContext(UserBalanceContext)
  const { userDetails } = useContext(UserDetailsContext)
  const temp_transaction_id = transactionId.replace(/^0+/, "")
  const temp_merchantId = merchantId.replace(/^0+/, "")

  const {
    data: transactionConfigData,
    refetch: refetchTransactionConfigData,
    isSuccess: isTransactionConfigSuccess,
    error: transactionConfigError
  } = useTransactionValidationConfig(transactionTypeControlQuery || "")

  const {
    mutate: sendDigitalCurrencyExternalWallet,
    isLoading: isSendExternalWalletLoading,
    error: sendExternalWalletError,
    isSuccess: isSendExternalWalletSuccess,
    data: sendExternalWalletData
  } = useSendDigitalCurrencyToExternalWallet()

  const getQrPaymentPayload = () => {
    if (temp_transaction_id.length > 0) {
      // merchant online
      return {
        amount: paymentAmount,
        asset: qrAsset,
        description: "merchant online",
        receiverAddress: location.state.qrAddress,
        uuid: transactionId
      }
    } else if (temp_merchantId.length > 0 && temp_transaction_id.length == 0) {
      // merchant offline
      return {
        amount: paymentAmount,
        asset: qrAsset,
        description: "merchant offline",
        receiverAddress: qrAddress,
        merchantId: merchantId,
        posId: posId
      }
    } else {
      // Peer_2_peer
      return {
        amount: paymentAmount,
        asset: qrAsset,
        description: "Peer 2 peer",
        receiverAddress: qrAddress
      }
    }
  }

  useEffect(() => {
    setIsButtonDisabled(true)
    setShowError("")

    if (paymentAmount >= 0) {
      if (paymentAmount === "") {
        return
      }

      if (insufficientBalance) {
        setShowError(t("insufficientFundsForTransfer"))
      }

      if (paymentAmount > userBalances) {
        setShowError(t("insufficientFundsForTransfer"))
      } else {
        const isTransactionPermitted =
          transactionConfigData?.per_TRANSACTION == null
            ? "allowed"
            : isTransactionAllowed(
                transactionConfigData,
                Number(paymentAmount),
                t
              ) // transaction control and loading

        if (qrAsset.length > 0 && qrAddress.length > 0 && qrAmount.length > 0) {
          if (isTransactionPermitted !== "allowed") {
            setShowError(isTransactionPermitted)
          } else if (
            (isTransactionPermitted == "allowed" && paymentAmount == "0") ||
            paymentAmount == "0." ||
            paymentAmount == "0.0" ||
            paymentAmount == "0.00"
          ) {
            setShowError(t("validAmountError"))
          } else if (qrMerchantName == userDetails?.email) {
            setShowError(
              t(
                `ERROR_MESSAGE.${"SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME"}`
              )
            )
          } else {
            setIsButtonDisabled(false)
          }
        } else {
          setLoading(false)
          setShowError("Invalid QR Code")
        }
      }
    }
  }, [paymentAmount])

  const walletNavigation = () => {
    const transactionConfirmationDataParam = {
      requestPayload: getQrPaymentPayload(),
      transactionType: getTransactionType(),
      screenType: getScreenType(),
      amountRequested: `${sartTxt} ${Number(paymentAmount).toFixed(2)}`,
      totalAmount: `${sartTxt} ${Number(paymentAmount).toFixed(2)}`,
      totalFee: 0.0,
      tokenAsset: "SART",
      transactionConfigData: transactionConfigData,
      ttc: transactionTypeControlQuery,
      feeTitle: "Net Amount Paid",
      netAmountCalulated: `${sartTxt} ${Number(paymentAmount).toFixed(2)}`,
      totalAmountText: "Amount to be paid"
    }

    navigate(RouteType.TRANSACTION_CONFIRMATION, {
      state: transactionConfirmationDataParam
    })
  }

  const getTransactionType = () => {
    if (temp_transaction_id.length > 0) {
      // merchant online
      // console.log("merchant online ")
      return "Payment"
    } else if (temp_merchantId.length > 0 && temp_transaction_id.length == 0) {
      // merchant offline
      // console.log("merchant offline")
      return "Payment"
    } else {
      // Peer_2_peer
      // console.log("Peer_2_peer")
      return "Transfer"
    }
  }

  const getScreenType = () => {
    if (temp_transaction_id.length > 0) {
      // merchant online
      // console.log("merchant online ")
      return "merchantOnline"
    } else if (temp_merchantId.length > 0 && temp_transaction_id.length == 0) {
      // merchant offline
      // console.log("merchant offline")
      return "merchantOffline"
    } else {
      // Peer_2_peer
      // console.log("Peer_2_peer")
      return "peer_2_peer"
    }
  }

  useEffect(() => {
    // check limit for transactions
    if (
      transactionConfigData &&
      transactionTypeControlQuery == TransactionTypeControl?.PURCHASE
    ) {
      const isTransactionPermitted =
        transactionConfigData?.per_TRANSACTION == null
          ? "allowed"
          : isTransactionAllowed(transactionConfigData, Number(qrAmount), t)

      setShowError("")

      if (isTransactionPermitted !== "allowed") {
        setIsButtonDisabled(true)
        setShowError(isTransactionPermitted)
      }
      if (userBalances < Number(qrAmount)) {
        setIsButtonDisabled(true)
        setShowError(t("insufficientFundsForTransfer"))
      }
    }
  }, [transactionConfigData, isTransactionConfigSuccess])

  useEffect(() => {
    if (isSendExternalWalletSuccess && sendExternalWalletData) {
      const response = sendExternalWalletData

      // Show notification
      showNotifications("success", t("paymentSuccessful"), "Notification")

      setLoading(false)

      navigate(RouteType.PAYMENTSUCCESS, {
        state: { from: RouteType.DASHBOARD, response: response }
      })
    }
  }, [isSendExternalWalletSuccess, sendExternalWalletData])

  useEffect(() => {
    if (sendExternalWalletError) {
      const description = sendExternalWalletError.message
      // Show notification
      setIsButtonDisabled(true)
      setShowError(description)

      setLoading(false)
    }
  }, [sendExternalWalletError])

  // hook to check QR valid or Not
  useEffect(() => {
    if (!qrAsset && !qrAddress && !qrAmount) {
      setIsButtonDisabled(true)
      setShowError("Invalid QR Code.")
    }
  }, [])

  useEffect(() => {
    //check if user and merchant name is similar

    if (qrMerchantName == userDetails?.email) {
      showNotifications(
        "error",
        t(`ERROR_MESSAGE.${"SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME"}`),
        t("scanAndPay")
      )
    }
  }, [])

  // const handleChange = (value?: any) => {
  //   setIsButtonDisabled(true)

  //   const reg = /^-?\d*(\.\d*)?$/

  //   const amountLimit = value.includes(".") ? 11 : 8
  //   if (value.length > amountLimit) {
  //     return false
  //   }

  //   if (value === "-" || value.startsWith(".") || value == "00") {
  //     setPaymentAmount("")
  //   } else if (reg.test(value) || value === "" || value === "-") {
  //     if (value.indexOf(".") >= 0 == false && value > 0) {
  //       value = value.replace(/^0+/, "")
  //     } else if (value.indexOf(".") >= 0) {
  //       value = value.replace(/^0+/, "0")
  //     }

  //     setPaymentAmount(
  //       value.indexOf(".") >= 0
  //         ? value.substr(0, value.indexOf(".")) +
  //             value.substr(value.indexOf("."), 3)
  //         : value
  //     )

  //     qrAmount = value
  //   }
  // }

  const handleChange = (value?: any) => {
    setIsButtonDisabled(true)

    const reg = /^\d*(\.\d{0,2})?$/ // Regex to allow only numbers with up to 2 decimal points

    if (value === "-" || value === "--") {
      setPaymentAmount("")
    } else if (value === ".") {
      // Automatically round single decimal point to 0
      setPaymentAmount("0.")
      qrAmount = "0."
    } else if (reg.test(value) || value === "") {
      const roundedValue =
        value !== "" && !(value.indexOf(".") >= 0) ? parseFloat(value) : value
      setPaymentAmount(roundedValue)
      qrAmount = roundedValue
    }
  }

  const showNotifications = (
    type: string,
    description: string,
    message: any = t("anErrorOccurred")
  ) => {
    // notification.destroy(1)
    if (type === "success") {
      notification["success"]({
        message: "Success",
        description,
        placement: "bottom"
      })
    } else {
      notification["error"]({
        message: message,
        description,
        placement: "bottom"
      })
    }
  }

  // available Tokens
  const availableTokens = t("availableTokens")
    .replace("sartTxt", sartTxt)
    .replace("totalUserBalances", getAmountTwoDecimal(userBalances))

  return (
    <>
      {/* {showInvalidQrMsg && alert("QR code you have scanned is invalid!!")} */}
      <div className="row">
        <div className="backgroundWhite"></div>
        <div
          className="col-lg-12 mb-3 send-external-component"
          style={{
            background: "#fff",
            padding: "20px"
          }}
        >
          {/* merchant payment image */}
          <Col className="mt-30">
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                marginTop: "70px"
              }}
            >
              <div className="circularBox">
                <Space
                  direction="horizontal"
                  style={{
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <img
                    src="/images/Confirm.png"
                    style={{
                      height: "50px",
                      width: "50px",
                      marginTop: "15px"
                    }}
                  />
                </Space>
              </div>
            </Space>
          </Col>

          {/* Merchant Name */}
          <Col>
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                color: "black",
                marginTop: "15px"
              }}
            >
              <h6
                className="mt-20"
                style={{ fontFamily: "Rubik-Medium", color: "BlackLight" }}
              >
                {qrMerchantName}
              </h6>
            </Space>
          </Col>

          {/* You are paying */}
          <Col>
            <Space
              style={{
                width: "100%",
                justifyContent: "center",
                color: "#757575",
                marginTop: "50px"
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontFamily: "Rubik-Regular",
                  color: "BlackLight"
                }}
              >
                {t("youArePaying")}
              </p>
            </Space>
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                color: "#757575",
                marginLeft: "5px"
              }}
            >
              <h5>
                <Row>
                  {" "}
                  <span
                    style={{
                      color: "midDarkToneGray",
                      fontFamily: "Rubik-Medium",
                      fontSize: "25px"
                    }}
                  >
                    {" "}
                    {sartTxt}&nbsp;
                    <Input
                      type="text"
                      value={paymentAmount}
                      readOnly={temp_transaction_id.length > 0 || loading}
                      id={"inputBox"}
                      style={{ width: "100px", color: "midDarkToneGray" }}
                      onChange={(x) => handleChange(x.target.value)}
                      placeholder="0.00"
                      autoFocus
                      autoComplete="off"
                    />
                  </span>
                </Row>
              </h5>
            </Space>
          </Col>
          <Col>
            {showError ? (
              <div
                style={{
                  textAlign: "center",
                  color: "#ef0000",
                  fontFamily: "Rubik-Regular",
                  position: "absolute",
                  left: "0px",
                  width: "100%"
                }}
              >
                {showError}
              </div>
            ) : null}
          </Col>
          <Col>
            <Input
              className="alignCenter"
              value={availableTokens}
              style={{
                marginTop: "50px",
                width: "100%",
                marginRight: "40px",
                color: "midDarkToneGray",
                fontFamily: "Rubik-Medium",
                height: "50px",
                borderColor: "#d9d9d9"
              }}
              size="large"
              readOnly={true}
            />
          </Col>

          <Col style={{ marginTop: "80px" }}>
            <Link
              to={RouteType.SCAN_QR_CODE}
              style={{ textDecoration: "none" }}
            >
              <Button
                style={{
                  width: "43%",
                  justifyContent: "center",
                  color: "black",
                  borderWidth: "0.5",
                  borderColor: "black",
                  height: "50px"
                }}
                size="large"
                disabled={loading}
              >
                {t("cancel")}
              </Button>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              size="large"
              style={{
                width: "43%",
                background: isButtonDisabled ? "#fff" : "#ffc235",
                border: isButtonDisabled ? "1px solid" : "#ffc235",
                color: "black",
                height: "50px"
              }}
              disabled={loading || isButtonDisabled}
              onClick={() => {
                walletNavigation()
              }}
            >
              {t("pay")} &nbsp;&nbsp;
              {loading ? (
                <Spin
                  style={{ color: "#000", marginLeft: "5px" }}
                  indicator={antIcon}
                />
              ) : null}
            </Button>
          </Col>
        </div>
      </div>
    </>
  )
}

export default SendExternalComponent
