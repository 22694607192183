import { RightOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { Carousel, Input, Form, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import React, { useContext, useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import "./InternalTransfer.scss"
import {
  useUserBalancesInfo,
  useTransactionValidationConfig,
  useGetUserProfileDetails
} from "src/api/user"
import { TransactionTypeControl } from "src/api/constants"
import { UserBalanceContext } from "src/context/User"

import {
  isTransactionAllowed,
  getAmountTwoDecimal,
  sartTxt
} from "../../utils/Utils"
import AlertPopupModal from "../AlertPopupModal"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const InternalTransfer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  // const [sarToken, setSarToken] = useState("")
  // Get previous data
  let previousFormData = {
    userContact: "",
    amount: ""
  }
  const savedFormData = localStorage.getItem("formData")
  if (savedFormData) {
    previousFormData = JSON.parse(savedFormData)
  }

  const inputRef = useRef<HTMLInputElement | any>(null)
  const [sarInput, setSarInput] = useState<any>(previousFormData?.amount)
  const [internalContact, setInternalContact] = useState<any>("")
  const [showError, setShowError] = useState("")
  const [contactError, setContactError] = useState("")
  const [inputFieldError, setInputFieldError] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const userContact =
    location?.state?.userContact || previousFormData?.userContact

  const { userBalances, setUserBalances } = useContext(UserBalanceContext)

  const {
    data: transactionConfigData,
    refetch: refetchTransactionConfigData,
    isSuccess: isTransactionConfigSuccess,
    error: transactionConfigError
  } = useTransactionValidationConfig(TransactionTypeControl.P2P_TRANSFER)

  const {
    data: profileData,
    isFetching: isFetchingProfileDetails,
    refetch: refetchGetUserProfileDetails,
    error: errorProfileDetails
  } = useGetUserProfileDetails()

  //console.log("userBalances", userBalances)
  // Get user balances API
  const {
    data: userBalancesData,
    isFetching: isFetchingUserBalance,
    error: errorUserBalance
  } = useUserBalancesInfo()

  useEffect(() => {
    if (userContact) {
      setInternalContact(userContact)
    }
  }, [userContact])

  const WalletNavigation = () => {
    if (isNaN(sarInput) || isNaN(parseFloat(sarInput))) {
      return
    }

    setLoading(true)
    setShowError("")
    setContactError("")

    const transactionConfirmationDataParam = {
      requestPayload: {
        receiverEmail: userContact?.email || "",
        asset: "SART",
        amount: Number(sarInput)?.toFixed(2)
      },
      transactionType: "Transfer",
      screenType: "addTransaction",
      amountRequested: `${sartTxt} ${Number(sarInput)?.toFixed(2)}`,
      totalAmount: `${sartTxt} ${Number(sarInput)?.toFixed(2)}`,
      totalFee: 0.0,
      tokenAsset: "SART",
      transactionConfigData: transactionConfigData,
      ttc: TransactionTypeControl.P2P_TRANSFER,
      feeTitle: "Net Transfer Amount",
      netAmountCalulated: `${sartTxt} ${Number(sarInput)?.toFixed(2)}`,
      totalAmountText: "Amount Transferred"
    }

    // Set formData
    localStorage.setItem(
      "formData",
      JSON.stringify({
        amount: Number(sarInput)?.toFixed(2),
        userContact
      })
    )

    navigate(RouteType.TRANSACTION_CONFIRMATION, {
      state: transactionConfirmationDataParam
    })
  }

  useEffect(() => {
    if (userBalancesData) {
      setUserBalances(userBalancesData.SART)
    }
  }, [isFetchingUserBalance])

  const contentStyle: React.CSSProperties = {
    padding: "0 0 0 0",
    height: "120px",
    color: "#000",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "20px"
  }

  const handleChange = (value?: any) => {
    setInputFieldError(false)
    setIsButtonDisabled(false)

    const reg = /^-?\d*(\.\d*)?$/

    // check for transaction limits
    const isTransactionPermitted =
      transactionConfigData?.per_TRANSACTION == null
        ? "allowed"
        : isTransactionAllowed(transactionConfigData, Number(value), t)

    const amountLimit = value.includes(".") ? 11 : 8
    if (value.length > amountLimit) {
      setIsButtonDisabled(true)
      return false
    }

    setShowError("")

    if (isTransactionPermitted !== "allowed") {
      setShowError(`${isTransactionPermitted}`)
      setSarInput("")
      setIsButtonDisabled(true)
    }

    if (userBalances < Number(value)) {
      setShowError(t("insufficientFundsForTransfer"))
      setIsButtonDisabled(true)
      setSarInput(
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
              value.substr(value.indexOf("."), 3)
          : value.replace(/^0+/, "")
      )
    }

    if (value.length > amountLimit) {
      return false
    }

    if (value.startsWith(".")) {
      value = value.replace(/^.+/, "0.")
    }

    if (value === "-") {
      setShowError(t("validAmountError"))
      setSarInput("")
      setIsButtonDisabled(true)
    } else if (
      (isTransactionPermitted === "allowed" && value == "0") ||
      value == "0." ||
      value == "0.0" ||
      value == "0.00"
    ) {
      setShowError(t("validAmountError"))
      setSarInput("")
      setIsButtonDisabled(true)
    }

    if (reg.test(value) || value === "" || value === "-") {
      if (value.indexOf(".") >= 0 == false && value > 0) {
        value = value.replace(/^0+/, "")
      } else if (value.indexOf(".") >= 0) {
        value = value.replace(/^0+/, "0")
      }

      setSarInput(
        value.indexOf(".") >= 0
          ? value.substr(0, value.indexOf(".")) +
              value.substr(value.indexOf("."), 3)
          : value
      )
    }
  }

  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem("token")
    if (token === undefined || token == null || token === "") {
      navigate(RouteType.AUTH_ERROR)
      window.location.reload()
    }
  }, [])

  const isActivationFeeCharged =
    profileData && !profileData?.isActivationFeeCharged

  return (
    <>
      <div className="geidea mb-50">
        <div
          className="row"
          style={{
            background: "#fff"
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "15px",
              marginTop: "35px"
            }}
          >
            {/* <h5 className="welcomeHeading">Welcome to Pilgrim Wallet</h5> */}
            <Carousel
              style={{ background: "#ececec" }}
              className="custom-carousel"
              effect={"scrollx"}
              easing={"linear"}
            >
              <div className="available-bal-box">
                <div className="available-bal-inner" style={contentStyle}>
                  <h6 className="internal-avail-bal-text">
                    {t("availableBalance")}
                  </h6>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <h5 style={{ marginLeft: "6px", marginTop: "10px" }}>
                      {sartTxt} {getAmountTwoDecimal(userBalances)}
                    </h5>
                  </div>
                </div>
              </div>
            </Carousel>

            <div className="internal-form-block">
              <div className="internal-form-text main-font-color">
                {t("recipientAddress")}
              </div>
              <div
                className="shadow internal-form-value"
                style={{
                  padding: "8px",
                  height: "60px",
                  border: "1px solid #e5e0e0",
                  borderRadius: "7px",
                  opacity: !profileData?.isP2PEnabled ? 0.5 : "unset",
                  pointerEvents: !profileData?.isP2PEnabled ? "none" : "auto",
                  background: !profileData?.isP2PEnabled
                    ? "rgb(229, 224, 224)"
                    : ""
                }}
                onClick={() => {
                  navigate(RouteType.MANAGE_CONTACTS, {
                    state: { from: RouteType.INTERNAL_TRANSFER }
                  })
                }}
              >
                <h6
                  style={{ color: "#7d7c7c", marginTop: "7px", padding: "5px" }}
                >
                  {internalContact
                    ? internalContact?.nickname === null
                      ? internalContact?.email
                      : internalContact?.nickname
                    : t(`pleaseSelect`)}
                </h6>
                <RightOutlined
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "12px",
                    opacity: !profileData?.isP2PEnabled ? 0.5 : "unset"
                  }}
                />
              </div>
              {/* </Link> */}
            </div>
            <div className="signinForm-error">
              {contactError.length > 0 ? contactError : ""}
            </div>
            <div className="internal-form-block">
              <div className="internal-form-text main-font-color">
                {t("enterAmount")}
              </div>
              <Form.Item noStyle rules={[{ required: true }]}>
                <Input
                  onChange={(x) => handleChange(x.target.value)}
                  placeholder="0.00"
                  defaultValue={sarInput}
                  value={sarInput}
                  size="large"
                  addonBefore={sartTxt}
                  status={inputFieldError ? "error" : undefined}
                  className="topup-input internalTransaction"
                  ref={inputRef}
                  disabled={!profileData?.isP2PEnabled}
                />
              </Form.Item>
              <div className="signinForm-error">
                {showError.length > 0 ? showError : ""}
              </div>
            </div>
          </div>

          <button
            style={{
              width: "80%",
              marginBottom: "100px",
              marginLeft: "40px",
              borderRadius: "5px"
            }}
            type="button"
            className={
              !internalContact || isButtonDisabled || !(Number(sarInput) > 0)
                ? "border-button-refund button-position-bottom default-btn-style"
                : "border-button-refund button-position-bottom active-btn-style"
            }
            onClick={WalletNavigation}
            disabled={
              loading ||
              isButtonDisabled ||
              !internalContact ||
              sarInput <= 0 ||
              !profileData?.isP2PEnabled
                ? true
                : false
            }
          >
            <h5 className="text-button-style">{t("transfer")}</h5>
            {loading ? (
              <Spin
                style={{ color: "#000", marginLeft: "5px" }}
                indicator={antIcon}
              />
            ) : null}
          </button>
        </div>
      </div>
      {isActivationFeeCharged && <AlertPopupModal isVisible={true} />}

      {profileData && !profileData?.isP2PEnabled && (
        <AlertPopupModal title={t("isP2PDisabled")} isVisible={true} />
      )}
    </>
  )
}

export default InternalTransfer
